import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import OfficialListItem from "../components/ListItems/OfficialListItem";
import Breadcrumb from "../components/Breadcrumb";
import Title from "../components/Title"

const OfficialList = ({pageContext, data, location}) => {
    const {
        currentPage,
        numPages,
    } = pageContext

    const officials = data.officials.nodes

    const officialsByFunction = [];

    officials.forEach((node) => {
        let group = node.relationships.function

        if (officialsByFunction[group.drupal_internal__tid] === undefined) {
            officialsByFunction[group.drupal_internal__tid] = {
                name: group.name,
                nodes: []
            };
        }

        officialsByFunction[group.drupal_internal__tid].nodes.push(node);
    });

    return (
        <Layout>
            <Metas title="Conseil Municipal" />
            <section className="section page-content">
                <div className="container mx-auto px-4">
                    <Breadcrumb/>

                    <Title title="Conseil Municipal" className="text-primary"/>

                    {officialsByFunction.map((group, index) => (
                        <div key={index} className={`group term-${index}`}>
                            <h2 className="my-8 text-primary">{group.name}</h2>
                            <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                                {group.nodes.map((node, index) => (
                                    <div key={index} className="column official">
                                        <OfficialListItem content={node} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}

                    <Pagination
                        path={location.pathname}
                        currentPage={currentPage}
                        numPages={numPages}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default OfficialList

export const query = graphql`
    query officialListQuery($skip: Int!, $limit: Int!) {
        officials: allNodeElu (
            filter: {status: {eq: true}}
            sort: { fields: relationships___field_role___weight, order: ASC }
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                id
                title
                presentation: field_presentation {
                    value
                }
                image: field_image {
                    alt
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 336, maxHeight: 404, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    function: field_role {
                        name
                        drupal_internal__tid
                    }
                }
            }
        }
    }`