import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import Small16by10 from "../Images/Small16by10";
import Truncate from "../Truncate";

const OfficialListItem = ({content}) => (
    <div className="official-list-item card relative flex flex-col h-full">
        <div className="card-image relative flex-initial pl-4">
            {content.relationships.image ? (
                <Img
                    fluid={content.relationships.image.localFile.childImageSharp.fluid}
                    alt={content.title}
                />
            ) : (
                <Small16by10 alt={content.title}/>
            )}
        </div>
        <div className="card-content flex-1 p-4 bg-primary-light pt-32 -mt-28">
            <div className="title h3 uppercase text-white">{content.title}</div>

            {content.presentation &&(
                <div
                    className="content text-white text-sm"
                    dangerouslySetInnerHTML={{__html: content.presentation.value}}
                />
            )}

        </div>
    </div>
)

OfficialListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default OfficialListItem;
